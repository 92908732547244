<template>
  <div>
    123
<!--    <img src="@/assets/a.jpg" style="width:300px;height:300px" ref="img" id="img1">-->
    <img src="http://192.168.1.122:8181/storage/app/20240316/5d929db61e36c35c1823b419d727477e.jpg" style="width:300px;height:300px" ref="img" id="img1">
    <button @click="getImgLocation">获取图片位置</button>
  </div>
</template>

<script>
  import EXIF from 'exif-js';
  export default {
    name: "test.vue",
    methods: {
      getImgLocation(){
        let _this = this
        EXIF.getData(this.$refs.img,function(){
          //图片包含的所有信息(例：拍照方向、相机设备型号、拍摄时间、ISO 感光度、GPS 地理位置等数据。)
          const imgAllInfo = EXIF.getAllTags(this);
          console.log(imgAllInfo);
          const imgLon = EXIF.getTag(this, 'GPSLongitude')
          const imgLat = EXIF.getTag(this, 'GPSLatitude')
          if(imgLon && imgLat){
            //计算出经纬度并保留6为小数
            const lon = (imgLon[0] + imgLon[1]/60 + imgLon[2]/60/60).toFixed(6)
            const lat = (imgLat[0] + imgLat[1]/60 + imgLat[2]/60/60).toFixed(6)
            //使用高德地图的逆地理编码，key申请类型为Web API服务
            //const mapKey = '自己申请的key值'
            //调用高得API
            console.log(lon, lat);
          } else {
            _this.address = '暂未获得该图片地址'
          }
        })
      },
      getImgLocation2() {
        let img1 = document.getElementById("img1");
        EXIF.getData(img1, function () {
          let make = EXIF.getTag(this, "Make");
          let model = EXIF.getTag(this, "Model");
          console.log(make, model)
          //var makeAndModel = document.getElementById("makeAndModel");
          //makeAndModel.innerHTML = `${make} ${model}`;
        });
        return false;
      }
    }
  }
</script>

<style scoped>

</style>
